import { Link, usePage } from '@inertiajs/react';
import classNames from 'classnames';
import Vapor from 'laravel-vapor';
import { ChangeEvent, useState } from 'react';
import { Image, Trash } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { setServerErrors, useMutation } from '../../Shared/api';
import ImageCropper from '../../Shared/Image/ImageCropper';
import { useLocale } from '../../Shared/locale';
import { useNotifier } from '../../Shared/Notifier/NotificationProvider';
import { AddressFields, Campaign, CampaignType, Charity, DateOfBirthFields, PhoneFields, Project, User } from '../../Shared/types';
import AddressInputs from '../../Shared/UI/AddressInputs';
import { Checkbox } from '../../Shared/UI/Checkbox';
import CheckboxLabel from '../../Shared/UI/CheckboxLabel';
import ErrorMessage from '../../Shared/UI/ErrorMessage';
import { FadeIn } from '../../Shared/UI/FadeIn';
import { FileInput } from '../../Shared/UI/FileInput';
import { Icon } from '../../Shared/UI/Icon';
import InfoMessage from '../../Shared/UI/InfoMessage';
import { Input } from '../../Shared/UI/Input';
import InputDescription from '../../Shared/UI/InputDescription';
import InputGroup from '../../Shared/UI/InputGroup';
import InputLabel from '../../Shared/UI/InputLabel';
import { InputWrapper } from '../../Shared/UI/InputWrapper';
import PhoneNumberInput from '../../Shared/UI/PhoneNumberInput';
import { Radio } from '../../Shared/UI/Radio';
import RadioLabel from '../../Shared/UI/RadioLabel';
import ScrollIntoView from '../../Shared/UI/ScrollIntoView';
import { SearchInput } from '../../Shared/UI/SearchInput';
import ServerErrors from '../../Shared/UI/ServerErrors';
import { Textarea } from '../../Shared/UI/Textarea';
import { useCurrencyInput } from '../../Shared/useCurrencyInput';
import { useDateInput } from '../../Shared/useDateInput';
import useStickyElement from '../../Shared/useStickyElement';
import { useRouter } from '../router';
import { PageProps } from '../types';
import { Button } from '../UI/Button';

interface EditCampaignFormProps {
  campaign: Campaign & AddressFields & DateOfBirthFields & PhoneFields & {
    project: Project;
    team: Campaign | null;
    user: User;
  };
  teams: Campaign[];
  charity: Charity;
  scrollToEmailSettings?: boolean;
}

export function EditCampaignForm({
  campaign,
  teams,
  charity,
  scrollToEmailSettings = false,
}: EditCampaignFormProps) {
  const { t } = useTranslation();
  const { handleCurrencyBlur, formatCurrency, parseCurrency } = useCurrencyInput();
  const { handleDateBlur, formatDate, parseDate } = useDateInput();
  const { routes } = useRouter();
  const { defaultCountry } = useLocale();
  const { params } = usePage<PageProps>().props;
  const notifier = useNotifier();

  const form = useForm({
    defaultValues: {
      title: campaign.title || '',
      image: null,
      delete_image: !campaign.image_url,
      start_date: campaign.start_date ? formatDate(campaign.start_date) : '',
      end_date: campaign.end_date ? formatDate(campaign.end_date) : '',
      fundraising_target: campaign.fundraising_target ? formatCurrency(campaign.fundraising_target) : '',
      description: campaign.description,
      donation_message: campaign.donation_message || '',
      team_id: campaign.team?.id || null,
      join_team: !!campaign.team,
      team_search: params.team_search,
      first_name: campaign.first_name || '',
      last_name: campaign.last_name || '',
      email: campaign.user.email || '',
      date_of_birth: campaign.date_of_birth ? formatDate(campaign.date_of_birth) : '',
      company_name: campaign.company_name || '',
      phone: campaign.phone || '',
      street: campaign.street || '',
      house_number: campaign.house_number || '',
      extra_address_line: campaign.extra_address_line || '',
      zip_code: campaign.zip_code || '',
      city: campaign.city || '',
      country: campaign.country || defaultCountry,
      email_opt_in: campaign.email_opt_in,
    },
  });

  const {
    register, control, setValue, handleSubmit, setError, resetField, watch,
    formState: { isSubmitting, errors, defaultValues, isDirty },
  } = form;

  const joinTeam = watch('join_team');
  const allowJoinTeam = campaign.type === CampaignType.Individual && ! campaign.locked_fields?.includes('team') && campaign.project.allow_teams;
  const allowLeaveTeam = campaign.type === CampaignType.Individual && ! campaign.locked_fields?.includes('team') && campaign.project.allow_individuals;
  const [selectedTeam, setSelectedTeam] = useState(campaign.team);

  // Disable some fields for team members
  const projectFields = (campaign.project.fields || []).filter(
    (field) => !joinTeam || !['date', 'company_name'].includes(field)
  );

  const [image, setImage] = useState<File>();
  const [croppedImage, setCroppedImage] = useState<File>();
  const deleteImage = watch('delete_image');
  const canDeleteImage = !deleteImage && (!!image || !!campaign.image_url);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setImage(event.target.files?.[0] || undefined);
    setCroppedImage(undefined);
    resetField('image'); // Reset errors
    setValue('delete_image', false, { shouldDirty: true });
  };

  const handleCropChange = (file: File, element: HTMLImageElement) => {
    if (element.naturalWidth < 800 || element.naturalHeight < 450) {
      resetImage();
      setError('image', { message: t('shared:validation.image_too_small') });
    } else {
      setCroppedImage(file);
    }
  };

  const resetImage = () => {
    setImage(undefined);
    setCroppedImage(undefined);
    resetField('image'); // Reset errors
    setValue('delete_image', true, { shouldDirty: true });
  };

  const startDate = watch('start_date');
  const email = watch('email');

  const [isCompany, setIsCompany] = useState(
    campaign.project.required_fields.includes('company_name') || !!campaign.company_name,
  );
  const toggleCompany = () => setIsCompany((isCompany) => !isCompany);

  const [editCampaign, { errors: serverErrors }] = useMutation(routes.edit_campaign(campaign.id), {
    onSuccess: () => {
      notifier.notify(t('frontend:campaign_saved'));
    },
    onError: (errors) => {
      setServerErrors(errors, setError, defaultValues);
    },
  });

  const stickyFooter = useStickyElement({
    position: 'bottom',
  });

  const submit = async (data: typeof defaultValues) => {
    await editCampaign({
      title: data?.title,
      image: image && croppedImage ? (await Vapor.store(croppedImage))?.key || null : null,
      delete_image: data?.delete_image,
      start_date: data?.start_date ? parseDate(data.start_date) : null,
      end_date: data?.end_date ? parseDate(data.end_date) : null,
      fundraising_target: data?.fundraising_target ? parseCurrency(data.fundraising_target) : null,
      description: data?.description,
      donation_message: data?.donation_message,
      team_id: data?.join_team ? selectedTeam?.id : null,
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      date_of_birth: data?.date_of_birth ? parseDate(data.date_of_birth) : null,
      company_name: isCompany ? data?.company_name : null,
      phone: data?.phone,
      street: data?.street,
      house_number: data?.house_number,
      extra_address_line: data?.extra_address_line,
      zip_code: data?.zip_code,
      city: data?.city,
      country: data?.country,
      email_opt_in: data?.email_opt_in,
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="space-y-6">
      {serverErrors && <ServerErrors errors={serverErrors} defaultValues={defaultValues} scrollIntoView />}

      <h4 className="font-medium text-slate-500">
        {t('frontend:campaign_details')}
      </h4>

      {allowJoinTeam && (
        <>
          {allowLeaveTeam && (
            <InputGroup>
              <InputLabel htmlFor="edit_campaign_no_team" required valid>
                {t('frontend:team')}
              </InputLabel>
              <RadioLabel>
                <Radio
                  checked={!joinTeam}
                  onChange={(event) => setValue('join_team', !event.target.checked, { shouldDirty: true })}
                  id="edit_campaign_no_team"
                />
                <span>
                  {t('frontend:no_team')}
                </span>
              </RadioLabel>
              <RadioLabel>
                <Radio
                  checked={joinTeam}
                  onChange={(event) => setValue('join_team', event.target.checked, { shouldDirty: true })}
                />
                <span>
                  {t('frontend:join_team')}
                </span>
              </RadioLabel>
            </InputGroup>
          )}

          <FadeIn
            when={joinTeam}
            render={() => (
              <InputGroup>
                <InputLabel required valid={!errors.team_id && !errors.team_search} htmlFor="team_search">
                  {t('frontend:join_team')}
                </InputLabel>
                {!selectedTeam && (
                  <>
                    <InputDescription>
                      <Trans i18nKey="frontend:create_new_team_if_not_exists_description">
                        <Link href={`${routes.register_page(campaign.project.id)}?type=team&member_id=${campaign.id}`} />
                      </Trans>
                    </InputDescription>
                    <Controller
                      control={control}
                      name="team_search"
                      rules={{ required: true }}
                      render={({ field: { onChange } }) => (
                        <SearchInput id="team_search" name="team" onChange={onChange} />
                      )}
                    />
                    {teams.length > 0 && (
                      <div className="space-y-1 mt-3">
                        {teams.map((team) => (
                          <RadioLabel className="border border-slate-150 hover:border-slate-250 rounded-md px-3 py-2" key={team.id}>
                            <Radio
                              {...register('team_id', {
                                onChange: (event) => setSelectedTeam(teams.find(team => team.id === event.target.value) || null),
                                required: true,
                              })}
                              value={team.id}
                            />
                            <div className="flex space-x-2 items-center">
                              <div className="w-8 h-6 rounded bg-project/10 flex items-center justify-center">
                                {team.thumbnail_url && (
                                  <img src={team.thumbnail_url} className="block object-cover w-full h-full rounded" />
                                )}
                                {!team.thumbnail_url && charity.logo_url && (
                                  <img src={charity.logo_url} className="block w-1/4 opacity-50" />
                                )}
                              </div>
                              <span>
                                {team.title}
                              </span>
                            </div>
                          </RadioLabel>
                        ))}
                      </div>
                    )}
                    {!!params.team_search && teams.length === 0 && (
                      <div className="text-slate-500 mt-2">
                        {t('frontend:no_results')}
                      </div>
                    )}
                  </>
                )}
                {selectedTeam && (
                  <CheckboxLabel className="mt-2 border border-slate-250 rounded-md px-3 py-2">
                    <Checkbox {...register('team_id', { onChange: () => setSelectedTeam(null) })} />
                    <div className="flex space-x-2 items-center">
                      <div className="w-8 h-6 rounded bg-project/10 flex items-center justify-center">
                        {selectedTeam.thumbnail_url && (
                          <img src={selectedTeam.thumbnail_url} className="block object-cover w-full h-full rounded" />
                        )}
                        {!selectedTeam.thumbnail_url && charity.logo_url && (
                          <img src={charity.logo_url} className="block w-1/4 opacity-50" />
                        )}
                      </div>
                      <span>
                        {selectedTeam.title}
                      </span>
                    </div>
                  </CheckboxLabel>
                )}
                <ErrorMessage error={errors.team_id || errors.team_search} attribute={t('frontend:team')} />
              </InputGroup>
            )}
          />
        </>
      )}

      <FadeIn
        when={!joinTeam}
        render={() => (
          <InputGroup>
            <InputLabel required valid={!errors.title} htmlFor="edit_campaign_title">
              {t('frontend:campaign_title')}
            </InputLabel>
            <InputDescription>
              {t('frontend:campaign_title_description')}
            </InputDescription>
            <Input
              {...register('title', { required: true })}
              id="edit_campaign_title"
              aria-invalid={!!errors.title}
              disabled={campaign.locked_fields?.includes('title')}
            />
            <ErrorMessage error={errors.title} attribute={t('frontend:campaign_title')} />
          </InputGroup>
        )}
      />

      <InputGroup>
        <InputLabel htmlFor="edit_campaign_image">
          {t('frontend:campaign_image')}
        </InputLabel>
        <InputDescription>
          {t('frontend:campaign_image_description')}
        </InputDescription>
        <div className="p-4 border rounded-md border-slate-250">
          <div
            className="flex items-center justify-center mb-4 transition-all rounded-sm bg-slate-500 aspect-video"
          >
            {!image && !deleteImage && campaign.image_url && (
              <img
                src={campaign.image_url}
                alt={campaign.title}
                className="object-cover w-full h-full rounded-sm"
              />
            )}
            {image && (
              <ImageCropper
                image={image}
                onChange={handleCropChange}
                aspect={16 / 9}
              />
            )}
            {((!image && !campaign.image_url) || deleteImage) && (
              <Icon className="!w-12 !h-12 text-slate-400">
                <Image strokeWidth={1} />
              </Icon>
            )}
          </div>
          <div className="flex space-x-2">
            <FileInput
              {...register('image')}
              accept=".jpg,.jpeg,.png"
              id="edit_campaign_image"
              aria-invalid={!!errors.image}
              onChange={handleImageChange}
            >
              <Icon className="mr-2">
                <Image />
              </Icon>
              {t('shared:form.select')}
            </FileInput>
            {canDeleteImage && (
              <Button onClick={() => resetImage()} variant="tertiary" size="sm">
                <Icon className="mr-2">
                  <Trash />
                </Icon>
                {t('shared:form.delete')}
              </Button>
            )}
          </div>
        </div>
        <ErrorMessage error={errors.image} attribute={t('frontend:campaign_image')} />
      </InputGroup>

      {projectFields.includes('date') && (
        <>
          <InputGroup>
            <InputLabel
              required={campaign.project.required_fields.includes('date')}
              valid={!errors.start_date}
              htmlFor="edit_campaign_start_date"
            >
              {t('frontend:start_date')}
            </InputLabel>
            <InputDescription>
              {t('frontend:campaign_start_date_description')}
            </InputDescription>
            <Input
              {...register('start_date', { required: campaign.project.required_fields.includes('date') })}
              onBlur={(event) => {
                handleDateBlur(event);
                setValue('start_date', event.target.value);
              }}
              id="edit_campaign_start_date"
              placeholder={t('shared:date_input.date_format_placeholder')}
              aria-invalid={!!errors.start_date}
            />
            <ErrorMessage error={errors.start_date} attribute={t('frontend:start_date')} />
          </InputGroup>
          <InputGroup>
            <InputLabel valid={!errors.end_date} htmlFor="edit_campaign_end_date">
              {t('frontend:end_date')}
            </InputLabel>
            <InputDescription>
              {t('frontend:campaign_end_date_description')}
            </InputDescription>
            <Input
              {...register('end_date')}
              onBlur={(event) => {
                handleDateBlur(event);
                setValue('end_date', event.target.value);
              }}
              id="edit_campaign_end_date"
              placeholder={startDate || t('shared:date_input.date_format_placeholder')}
              aria-invalid={!!errors.end_date}
            />
            <ErrorMessage error={errors.end_date} attribute={t('frontend:date')} />
          </InputGroup>
        </>
      )}

      {projectFields.includes('fundraising_target') && (
        <InputGroup>
          <InputLabel
            required={campaign.project.required_fields.includes('fundraising_target')}
            valid={!errors.fundraising_target}
            htmlFor="edit_campaign_fundraising_target"
          >
            {t('frontend:fundraising_target')}
          </InputLabel>
          <InputDescription>
            {t('frontend:fundraising_target_description', { charity: charity.title })}
          </InputDescription>
          <InputWrapper>
            <label htmlFor="edit_campaign_fundraising_target">
                  €
            </label>
            <Input
              {...register('fundraising_target', {
                required: campaign.project.required_fields.includes('fundraising_target'),
              })}
              id="edit_campaign_fundraising_target"
              className="pl-7"
              onBlur={(event) => {
                handleCurrencyBlur(event);
                setValue('fundraising_target', event.target.value);
              }}
              aria-invalid={!!errors.fundraising_target}
              min="0"
            />
          </InputWrapper>
          <ErrorMessage error={errors.fundraising_target} attribute={t('frontend:fundraising_target')} />
        </InputGroup>
      )}

      <InputGroup>
        <InputLabel htmlFor="edit_campaign_description">
          {t('frontend:campaign_description')}
        </InputLabel>
        <InputDescription>
          {t('frontend:campaign_description_description')}
        </InputDescription>
        <Textarea
          {...register('description')}
          id="edit_campaign_description"
          aria-invalid={!!errors.description}
          rows={12}
        />
        <ErrorMessage error={errors.description} attribute={t('frontend:campaign_description')} />
      </InputGroup>

      <InputGroup>
        <InputLabel htmlFor="edit_campaign_donation_message">
          {t('frontend:donation_success_message')}
        </InputLabel>
        <InputDescription>
          {t('frontend:donation_success_message_description')}
        </InputDescription>
        <Textarea
          {...register('donation_message')}
          id="edit_campaign_donation_message"
          aria-invalid={!!errors.donation_message}
          rows={6}
        />
        <ErrorMessage error={errors.donation_message} attribute={t('frontend:donation_success_message')} />
      </InputGroup>

      <hr className="border-slate-200" />

      <h4 className="font-medium text-slate-500">
        {t('frontend:your_details')}
      </h4>

      <div className="grid grid-cols-2 gap-6">
        <InputGroup>
          <InputLabel required valid={!errors.first_name} htmlFor="edit_campaign_first_name">
            {t('frontend:first_name')}
          </InputLabel>
          <Input
            {...register('first_name', { required: true })}
            id="edit_campaign_first_name"
            aria-invalid={!!errors.first_name}
            disabled={campaign.locked_fields?.includes('user')}
          />
          <ErrorMessage attribute={t('frontend:first_name')} error={errors.first_name }/>
        </InputGroup>

        <InputGroup>
          <InputLabel required valid={!errors.last_name} htmlFor="edit_campaign_last_name">
            {t('frontend:last_name')}
          </InputLabel>
          <Input
            {...register('last_name', { required: true })}
            id="edit_campaign_last_name"
            aria-invalid={!!errors.last_name}
            disabled={campaign.locked_fields?.includes('user')}
          />
          <ErrorMessage attribute={t('frontend:last_name')} error={errors.last_name }/>
        </InputGroup>
      </div>

      <InputGroup>
        <InputLabel required valid={!errors.email} htmlFor="edit_campaign_email">
          {t('frontend:email_address')}
        </InputLabel>
        <Input
          {...register('email', { required: true })}
          inputMode="email"
          id="edit_campaign_email"
          aria-invalid={!!errors.email}
          disabled={campaign.locked_fields?.includes('user')}
        />
        <ErrorMessage attribute={t('frontend:email_address')} error={errors.email }/>
        {email !== campaign.user.email && (
          <InfoMessage variant="alert" className="mt-4">
            {t('frontend:edit_email_message')}
          </InfoMessage>
        )}
      </InputGroup>

      {projectFields.includes('date_of_birth') && (
        <InputGroup>
          <InputLabel
            required={campaign.project.required_fields.includes('date_of_birth')}
            valid={!errors.date_of_birth}
            htmlFor="edit_campaign_date_of_birth"
          >
            {t('frontend:date_of_birth')}
          </InputLabel>
          <Input
            {...register('date_of_birth', { required: campaign.project.required_fields.includes('date_of_birth') })}
            onBlur={(event) => {
              handleDateBlur(event);
              setValue('date_of_birth', event.target.value);
            }}
            id="edit_campaign_date_of_birth"
            placeholder={t('shared:date_input.date_format_placeholder')}
            aria-invalid={!!errors.date_of_birth}
          />
          <ErrorMessage error={errors.date_of_birth} attribute={t('frontend:date_of_birth')} />
        </InputGroup>
      )}

      {projectFields.includes('company_name') && (
        <>
          {!campaign.project.required_fields.includes('company_name') && (
            <InputGroup>
              <CheckboxLabel>
                <Checkbox
                  checked={isCompany}
                  onChange={toggleCompany}
                  id="edit_campaign_company_toggle"
                  className="mt-1"
                />
                <span>
                  {t('frontend:create_company_campaign')}
                </span>
              </CheckboxLabel>
            </InputGroup>
          )}
          <FadeIn
            when={isCompany}
            render={() => (
              <InputGroup>
                <InputLabel
                  required={isCompany}
                  valid={!errors.company_name}
                  htmlFor="edit_campaign_company_name"
                >
                  {t('frontend:company_name')}
                </InputLabel>
                <Input
                  {...register('company_name', {
                    required: isCompany,
                  })}
                  id="edit_campaign_company_name"
                  aria-invalid={!!errors.company_name}
                />
                <ErrorMessage error={errors.company_name} attribute={t('frontend:company_name')} />
              </InputGroup>
            )}
          />
        </>
      )}

      {projectFields.includes('phone') && (
        <InputGroup>
          <InputLabel
            required={campaign.project.required_fields.includes('phone')}
            valid={!errors.phone}
            htmlFor="edit_campaign_phone"
          >
            {t('frontend:phone_number')}
          </InputLabel>
          <Controller
            control={control}
            name="phone"
            rules={{
              required: campaign.project.required_fields.includes('phone'),
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <PhoneNumberInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                country={campaign.country}
                id="edit_campaign_phone"
                ariaInvalid={!!errors.phone}
              />
            )}
          />
          <ErrorMessage error={errors.phone} attribute={t('frontend:phone_number')} />
        </InputGroup>
      )}

      {projectFields.includes('address') && (
        <AddressInputs form={form} required={campaign.project.required_fields.includes('address')} />
      )}

      <hr className="border-slate-200" />

      <h4 className="font-medium text-slate-500">
        {t('frontend:mails')}
      </h4>

      <ScrollIntoView when={scrollToEmailSettings}>
        <InputGroup>
          <InputLabel valid={!errors.email_opt_in} htmlFor="campaign_email_opt_in">
            {t('frontend:email_notifications')}
          </InputLabel>
          <CheckboxLabel>
            <Checkbox
              {...register('email_opt_in')}
              id="campaign_email_opt_in"
            />
            <span>
              {t('frontend:campaign_email_opt_in_text')}
            </span>
          </CheckboxLabel>
        </InputGroup>
      </ScrollIntoView>

      <hr className="border-slate-200" />

      <div ref={stickyFooter.anchorRef} className="!mt-0">
        <div
          ref={stickyFooter.elementRef}
          className={classNames(isDirty && stickyFooter.isSticky && 'fixed z-20 w-full bg-white border-t')}
          style={isDirty && stickyFooter.isSticky ? stickyFooter.position : {}}
        >
          <div className={classNames(isDirty && stickyFooter.isSticky ? 'max-w-2xl mx-auto px-4 sm:px-8 py-3' : 'pt-6')}>
            <Button
              type="submit"
              loading={isSubmitting}
              className="block w-full"
            >
              {t('shared:form.save')}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
